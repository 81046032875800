/* eslint-disable no-restricted-globals */
import * as React from 'react';
import { AppHeader } from './AppHeader/AppHeader';
import { AppFooter } from './AppFooter';
import glamorous, { ThemeProvider } from 'glamorous';
import { Switch, Route, Router } from 'react-router-dom';
import { mainTheme } from '../Theme';
import { initializeIcons } from '@uifabric/icons';
import createBrowserHistory from 'history/createBrowserHistory';
import { Dashboard, 
         MatterSummary,
         MatterSummaryDrillDown,
         MatterDetails,         
         Redirect,
         RvPrint,
         RvPrint2,
         Logout,
         RvDashboardOld,
         Settings,
         LeapProvisioning,
         LeapSignIn,
         LeapProvisioningInProgress,
         VerifyNewUser,
         LeapAccessResetSignIn,
         LeapAccessReset,
         Unauthorised,
         ClientAndContactDetails,
         TimeslipPage,
         AutoSignIn,
         ExternalEnquiry,
         UserDashboard,
         RvAdminDashboard,
         UserListPage,
         TimesheetActivityPage,
         MessageActivityPage,
         RoleSummaryPage,
         LearnMorePage,
         InvoiceSummaryPage,
         HelpConfigurationPage,
         UserConfigurationPage,
         ReferrersPage,
         CreateEnquiryPage,
         EmailTemplatesConfigurationPage,
         LookupConfigurationPage,
         ConfigurationDashboardPage,
         FeeEarnerDashboardPage,
         Home,
         LawyerDashboardPage,
         LeadsDashboardPage,
         EnquirySummaryPage,
         FeatureAccessConfigurationPage,
          FeatureLayoutConfigurationPage,
         FeatureLicenceConfigurationPage,         
         LeadsDetailPage,
         LoginActivityChartPage,
         LoginActivityPage,
         MailingListPage,
         MatterAccessChartPage,
         MatterAccessPage,
         MatterManagementPage,
         NameAccessChartPage,
         NameAccessPage,
         NameSummaryPage,
         ReportCatalogInformation,
         ReportInformation,
         ReportsCatalogPage,
         ReportsPage,
         ReportViewer,
         SplashScreenConfigurationPage,
         StaffBillingSummaryPage,
         StaffSummaryPage,
         TasksSummaryPage,
         TimelinesChartPage,
         TimesheetDrilldownPage,
         TimesheetPage,
         UploadDocumentDialog,
         TaskDashboardPage,
         TaskSummaryDrilldownPage,
         BrandConfigurationPage,
         EventsAndCampaignsPage,
         ClientDashboardPage,
         GeneralMaterialConfigurationPage,
         CrmResourceCenterPage,
         AcuityResourceCenterPage,
         MattersResourceCenterPage,
         TimesheetResourceCenterPage,
         TaskResourceCenterPage,
         RoleSecurityConfigurationPage,
         EventsAndCampaignsAttendeePage,
         ClientDocumentsPage,
         DashboardAccessPage,
         UserDashboardActivityListPage,
         MatterColumnSecurityConfigurationPage,
         NotesSummaryPage,
         MarketingListAccessPage,
         MarketingListAccessChartPage,
         EmailSendFailurePage,
         EmailSendFailureChartPage
        } from './LoadComponentAsync';
import EventSystem from '../EventSystem';
import UrlViewer from './UrlViewer';
import { UploadMatterDocumentNotification } from '../matterSummary/MatterSummaryRepository';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import { client } from '..';
import { showNotification } from '../App';
import { Subscription } from 'apollo-client/util/Observable';
import Login from './Login';
import { SupportEmailDialog } from '../home/Support/SupportEmailPage';
import IntegrationConfigurationPage from '../integrationConfiguration/IntegrationConfigurationPage';
import FirmSettingConfigurationPage from '../firmSettingConfiguration/FirmSettingConfigurationPage';
import FirmStructureConfigurationPage from '../firmStructureConfiguration/FirmStructureConfigurationPage';
import ApplicationSettingsPage from '../applicationSettings/ApplicationSettingsPage';
import InstructMatterFormDialog from '../instructMatter/InstructMatterFormDialog';
import { CreateInstructMatterSubscription, CreatePendingMattersSubscription } from '../instructMatter/InstructMatterRepositories';
import TimesheetTimerDrawer from '../timesheetTimer/TimesheetTimerDrawer';
import GeneralMaterialPage from '../GeneralMaterial/GeneralMaterialPage';
import gql from 'graphql-tag';
import i18n from '../i18n';
import AppMenu from './AppHeader/AppMenu';
import { getLanguageCodeByCountry } from '../locales/common';
import { ApolloQueryResult } from 'apollo-client';
import { LocalStorageEnum } from '../enums/LocalStorageEnum';
import EnquiryCustomFieldsPage from '../enquiryCustomFields/EnquiryCustomFieldsPage';
// import { TestComponentPage } from '../testComponent/TestComponentPage';

var NotificationSystem = require('react-notification-system');
export const browserHistory = createBrowserHistory();

const SiteParent = glamorous.div({
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    [`& > .${`header`}`]: {
        boxShadow: '0 1px 2px 0 rgba(81,115,255,0.17)',
        position: 'fixed',
        background: '#fff',
        width: '100%',
        zIndex: 9,
        height: '99px',
    },
    '& .timeslip-header': {
        display: 'none',
    },
    '& .custom-site-content': {
        paddingTop: '99px',
    },
    [`.${`site-onprint`}`]: {
        [`& .${`user-list-wrapper`}`]: {
            [`& .${`container-userlist`}`]: {
                display: 'none'
            },
            [`& .${`container-usercontent`}`]: {
                padding: 0,
                [`& .${`panel-container`}`]: {
                  display: 'none'
                },            
                [`& .${`content-wrapper`}`]: {
                    padding: 0
                },   
                [`& .${`table-container`}`]: {
                  padding: 0
                }          
            }                  
        },
        [`& .${`mattersummry-wrapper`}`]: {
            [`& .${`banner-container`}`]: {
                display: 'none'
            },
            [`& .${`content-wrapper`}`]: {
                padding: 0,
            },
            [`& .${`content-container`}`]: {
                padding: 0,
            },        
            [`& .${`butoncontainer`}`]: {
                display: 'none'
            },
            [`& .${`summaryview-wrapper`}`]: {
                padding: 0
            },
        }          
    }    
});

interface AppProps {
    // tslint:disable-next-line: no-any
    notificationSystem?: any;
}

interface AppState {
    isAuthenticated: boolean;
    tenantId: string;
    showMenu: boolean;
    isPrint?: boolean;
    isForceToChangePass?: boolean;
    isUploadDialogOpen: boolean;
    isSupportDialogOpen: boolean;
    isInstructMatterDialogOpen: boolean;
    isTimerDrawerOpen: boolean;
}

export class AppContent extends React.Component<AppProps, AppState> { 
    private unAuthenticatedUserKey: string;
    private authenticatedUserKey: string;
    private uploadMatterDocumentSubscription: Subscription;
    private pendingMatterSubscription: Subscription;
    private createInstructMatterSubscription: Subscription;
    private isSubscribed: boolean;

    // tslint:disable-next-line:no-any
    constructor(props: AppProps, state: AppState) {
        super(props, state);
        this.onUploadDocuments = this.onUploadDocuments.bind(this);
        this.onUploadClose = this.onUploadClose.bind(this);
        this.onRequestSupport = this.onRequestSupport.bind(this);
        this.onRequestSupportClose = this.onRequestSupportClose.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.onTenant = this.onTenant.bind(this);
        this.onShowMenu = this.onShowMenu.bind(this);
        this.getAppHeaderKey = this.getAppHeaderKey.bind(this);
        this.onClickInstructMatter = this.onClickInstructMatter.bind(this);
        this.onInstructMaterClose = this.onInstructMaterClose.bind(this);
        this.subscribePendingMatter = this.subscribePendingMatter.bind(this);
        this.subscribeInstructMatter = this.subscribeInstructMatter.bind(this);
        this.onToggleDrawer = this.onToggleDrawer.bind(this);
        this.getDefaultSetting = this.getDefaultSetting.bind(this);

        this.unAuthenticatedUserKey = Math.random().toString();
        this.authenticatedUserKey = Math.random().toString();

        var tenantID = sessionStorage.getItem('tenantId');
        var accessToken = sessionStorage.getItem('accessToken');
        var mfaCodeRequired = sessionStorage.getItem('mfaCodeRequired');
        
        let isAuthenticated = (mfaCodeRequired === null 
            ? accessToken != null && accessToken !== '' 
            : (mfaCodeRequired !== null && mfaCodeRequired !== '1') && (accessToken != null && accessToken !== ''));

        if (!tenantID) { tenantID = ''; }

        this.state = {        
            isAuthenticated: isAuthenticated,
            tenantId: tenantID,
            showMenu: false,
            isPrint: false,
            isForceToChangePass: false,
            isUploadDialogOpen: false,
            isSupportDialogOpen: false,
            isInstructMatterDialogOpen: false,
            isTimerDrawerOpen: false
        };

        initializeIcons(undefined, { disableWarnings: true });

        EventSystem.on('onprint:true', () => {
            this.setState({isPrint: true});
        });

        EventSystem.on('onprint:false', () => {
          this.setState({isPrint: false});
        });   
        
        let hideHeadFooter = location.pathname.toLowerCase() === '/leapprovisioninginprogress' 
                || location.pathname.toLowerCase() === '/leapprovisioning'
                || location.pathname.toLowerCase() === '/leapaccessreset'
                || location.pathname.toLowerCase() === '/feeearnerdashboard/leap'
                || location.pathname.toLowerCase() === '/dashboard/leap'
                || location.pathname.toLowerCase() === '/enquiriessummary/leap'
                || location.pathname.toLowerCase() === '/enquiriesdashboard/leap'
                || location.pathname.toLowerCase() === '/clientsandcontacts/leap'
                || location.pathname.toLowerCase() === '/referrers/leap'
                || location.pathname.toLowerCase() === '/mailinglist/leap'
                || location.pathname.toLowerCase().includes('/leapreports/');

        if (hideHeadFooter) {
            // tslint:disable-next-line:no-any
            window.addEventListener('message', function(event: MessageEvent<any>) { 
                
                // tslint:disable-next-line: no-shadowed-variable
                var accessToken = event.data.accessToken;

                if (accessToken) {
                    
                    var existingAccessToken = sessionStorage.getItem('accessToken');

                    if (existingAccessToken === null) {
                        sessionStorage.setItem('accessToken', accessToken);

                        // Reload the page to reset the Graphql AuthLink. Other approaches didn't work.
                        window.location.reload();
                    }
                }     
            // tslint:disable-next-line: align           
            }, false);
        }
    }   

    public componentDidMount() {
        if (this.state.isAuthenticated) {
            if (!this.isSubscribed) {
                this.isSubscribed = true;
                this.subscribeToUploadMatterDocument();
            }
            this.subscribePendingMatter();
            this.subscribeInstructMatter();
            this.getDefaultSetting();
        }
    }

    public componentWillUnmount() {
        if (this.uploadMatterDocumentSubscription) {
            this.uploadMatterDocumentSubscription.unsubscribe();
        }
        if (this.pendingMatterSubscription) {
            this.pendingMatterSubscription.unsubscribe();
        }
        if (this.createInstructMatterSubscription) {
            this.createInstructMatterSubscription.unsubscribe();
        }
    }

    render() {    
        let routes = (
          <Switch>

            {/* <Route path="/testComponent" exact={true} component={TestComponentPage} /> */}

            <Route path="/notesSummary" exact={true} component={NotesSummaryPage} />

            <Route path="/generalMaterialConfiguration" exact={true} component={GeneralMaterialConfigurationPage} />

            <Route path="/clientDashboard" exact={true} component={ClientDashboardPage} />

            <Route path="/eventsAndCampaigns" exact={true} component={EventsAndCampaignsPage} />

            <Route path="/eventsAndCampaigns/attendee/:title/:id" exact={true} component={EventsAndCampaignsAttendeePage} />

            <Route path="/brandConfiguration" exact={true} component={BrandConfigurationPage} />

            <Route path="/taskDashboard" exact={true} component={TaskDashboardPage} />
            
            <Route path="/tasksSummaryDrilldown" exact={true} component={TaskSummaryDrilldownPage} />

            <Route path="/timesheetResourceCenter" exact={true} component={TimesheetResourceCenterPage} />

            <Route path="/timesheetResourceCenter/:command" exact={true} component={TimesheetResourceCenterPage} />

            <Route path="/taskResourceCenter" exact={true} component={TaskResourceCenterPage} />

            <Route path="/taskResourceCenter/:command" exact={true} component={TaskResourceCenterPage} />

            <Route path="/crmResourceCenter" exact={true} component={CrmResourceCenterPage} />

            <Route path="/resourceCenter/:command" exact={true} component={CrmResourceCenterPage} />

            <Route path="/acuityResourceCenter" exact={true} component={AcuityResourceCenterPage} />

            <Route path="/mattersResourceCenter" exact={true} component={MattersResourceCenterPage} />

            {/* <Route path="/learnMore" exact={true} component={LearnMorePage} /> */}
            <Route path="/learnMore/:command" exact={true} component={LearnMorePage} />
            
            <Route path="/invoiceSummary" exact={true} component={InvoiceSummaryPage} />

            <Route path="/userDashboard" exact={true} component={UserDashboard} />

            {/* help configuration */}
            <Route path="/helpConfiguration" exact={true} component={HelpConfigurationPage} />

            {/* user configuration */}
            <Route path="/userConfiguration" exact={true} component={UserConfigurationPage} />

            {/* referrers  page*/}
            <Route path="/referrers" exact={true} component={ReferrersPage} />

            {/* createEnquiry page */}
            <Route path="/createEnquiry" exact={true} component={CreateEnquiryPage} />

            {/* referrer details */}
            <Route path="/referrerDetails" exact={true} component={ClientAndContactDetails} />
            <Route path="/referrerDetails/" exact={true} component={ClientAndContactDetails} />
            <Route path="/referrerDetails/:id" exact={true} component={ClientAndContactDetails} />
            <Route path="/referrerDetails/:id/" exact={true} component={ClientAndContactDetails} />
            <Route path="/referrerDetails/:id/:tab" exact={true} component={ClientAndContactDetails} />

            {/* email templates */}
            <Route path="/emailConfiguration" exact={true} component={EmailTemplatesConfigurationPage} />
            <Route path="/emailConfiguration/:tab" exact={true} component={EmailTemplatesConfigurationPage} />

            {/* configuration */}
            <Route path="/lookupConfiguration" exact={true} component={LookupConfigurationPage} />
            <Route path="/lookupConfiguration/:tab" exact={true} component={LookupConfigurationPage} />
            <Route path="/lookupConfiguration/:tab/:command" exact={true} component={LookupConfigurationPage} />

            {/* integration */}
            <Route path="/integrationConfiguration" exact={true} component={IntegrationConfigurationPage} />
            <Route path="/integrationConfiguration/:tab" exact={true} component={IntegrationConfigurationPage} />
            <Route path="/integrationConfiguration/:tab/:command" exact={true} component={IntegrationConfigurationPage} />

            {/* integration */}
            <Route path="/firmSettingConfiguration" exact={true} component={FirmSettingConfigurationPage} />
            <Route path="/firmSettingConfiguration/:tab" exact={true} component={FirmSettingConfigurationPage} />
            <Route path="/firmSettingConfiguration/:tab/:command" exact={true} component={FirmSettingConfigurationPage} />

            {/* applciation settings */}
            <Route path="/applicationSettings" exact={true} component={ApplicationSettingsPage} />
            <Route path="/applicationSettings/:tab" exact={true} component={ApplicationSettingsPage} />
            <Route path="/applicationSettings/:tab/:command" exact={true} component={ApplicationSettingsPage} />

            {/* enquiry custom fields */}
            <Route path="/enquiryCustomFieldsConfiguration" exact={true} component={EnquiryCustomFieldsPage} />
            <Route path="/enquiryCustomFieldsConfiguration/:tab" exact={true} component={EnquiryCustomFieldsPage} />
            <Route path="/enquiryCustomFieldsConfiguration/:tab/:command" exact={true} component={EnquiryCustomFieldsPage} />

            {/* firm structure configuration */}
            <Route path="/firmStructureConfiguration" exact={true} component={FirmStructureConfigurationPage} />
            <Route path="/firmStructureConfiguration/:tab" exact={true} component={FirmStructureConfigurationPage} />
            <Route path="/firmStructureConfiguration/:tab/:command" exact={true} component={FirmStructureConfigurationPage} />
            
            <Route path="/lawyerDashboard" exact={true} component={LawyerDashboardPage} />
            <Route path="/timeslip" exact={true} component={TimeslipPage} />
            <Route path="/matterDetail" exact={true} component={MatterDetails} />        
            <Route path="/matterDetail/" exact={true} component={MatterDetails} />        
            <Route path="/matterDetail/:id" exact={true} component={MatterDetails} />
            <Route path="/matterDetail/:id/" exact={true} component={MatterDetails} />
            <Route path="/matterDetail/:id/:tab" exact={true} component={MatterDetails} />
            <Route path="/matterSummary" exact={true} component={MatterSummary} />
            <Route path="/matterSummaryDrillDown" exact={true} component={MatterSummaryDrillDown} />
            <Route path="/matterManagement" exact={true} component={MatterManagementPage} />
            <Route path="/clientsAndContacts" exact={true} component={NameSummaryPage} />
            <Route path="/timesheet" exact={true} component={TimesheetPage} />
            <Route path="/staffSummary" exact={true} component={StaffSummaryPage} />
            <Route path="/userDashboard" exact={true} component={UserDashboard} />
            <Route path="/reports" exact={true} component={ReportsPage} />
            <Route path="/reports/viewer/:id/:command" exact={true} component={ReportViewer} />
            <Route path="/reports/info/:id" exact={true} component={ReportInformation} />
            <Route path="/reportsCatalog" exact={true} component={ReportsCatalogPage} />
            <Route path="/reportsCatalog/info/:id" exact={true} component={ReportCatalogInformation} />

            <Route path="/clientAndContactDetail" exact={true} component={ClientAndContactDetails} />
            <Route path="/clientAndContactDetail/" exact={true} component={ClientAndContactDetails} />
            <Route path="/clientAndContactDetail/:id" exact={true} component={ClientAndContactDetails} />
            <Route path="/clientAndContactDetail/:id/" exact={true} component={ClientAndContactDetails} />
            <Route path="/clientAndContactDetail/:id/:tab" exact={true} component={ClientAndContactDetails} />
            <Route path="/unauthorised" exact={true} component={Unauthorised} />

            <Route path="/feeEarnerDashboard" exact={true} component={FeeEarnerDashboardPage} />
            <Route path="/feeEarnerDashboard/leap" exact={true} component={FeeEarnerDashboardPage} />
            
            <Route 
                path="/dashboardLayoutConfiguration" 
                exact={true} 
                render={(props) => <FeatureLayoutConfigurationPage {...props} />}
            />         
            <Route path="/splashScreenConfiguration" exact={true} component={SplashScreenConfigurationPage} />
            <Route path="/featureLicenceConfiguration" exact={true} component={FeatureLicenceConfigurationPage} />
            <Route path="/featureAccessConfiguration" exact={true} component={FeatureAccessConfigurationPage} />
            <Route path="/configurationDashboard" exact={true} component={ConfigurationDashboardPage} />
            <Route path="/matterManagement" exact={true} component={MatterManagementPage} />
            <Route path="/roleSecurityConfiguration" exact={true} component={RoleSecurityConfigurationPage} />
            <Route path="/matterViewColumnSecurityConfiguration" exact={true} component={MatterColumnSecurityConfigurationPage} />

            <Route 
                path="/matterSummary/:command" 
                exact={true} 
                render={(props) => <MatterSummary {...props} />}
            />
            
            <Route path="/LeapSignIn" exact={true} component={LeapSignIn} />    
            <Route path="/LeapAccessResetSignIn" exact={true} component={LeapAccessResetSignIn} />    
            <Route path="/LeapProvisioning/" exact={true} component={LeapProvisioning} />
            <Route path="/LeapAccessReset" exact={true} component={LeapAccessReset} />    
            <Route path="/LeapProvisioningInProgress/" exact={true} component={LeapProvisioningInProgress} />
            <Route path="/matterSummary/list/print" exact={true} component={RvPrint} />
            <Route path="/matterSummary/list/print2" exact={true} component={RvPrint2} />
            <Route path="/dashboard2" exact={true} component={Dashboard} />
            <Route path="/dashboardOld" exact={true} component={RvDashboardOld} />
            <Route path="/dashboard" exact={true} component={Home} />
            <Route path="/dashboard/leap" exact={true} component={Home} />
            <Route path="/admin" exact={true} component={RvAdminDashboard} />
            <Route path="/admin/userList" exact={true} component={UserListPage} />
            <Route path="/admin/userList/:command" exact={true} component={UserListPage} />
            <Route path="/enquiriesSummary" exact={true} component={EnquirySummaryPage} />
            <Route path="/enquiriesSummary/leap" exact={true} component={EnquirySummaryPage} />
            <Route path="/enquiriesDetail/:id" exact={true} component={LeadsDetailPage} />
            <Route path="/enquiriesSummary/:id" exact={true} component={EnquirySummaryPage} />
            <Route path="/enquiriesDashboard" exact={true} component={LeadsDashboardPage} />
            <Route path="/tasksSummary" exact={true} component={TasksSummaryPage} />
            <Route path="/tasksSummary/:id/:taskType" exact={true} component={TasksSummaryPage} />
            <Route path="/tasksSummary/:id" exact={true} component={TasksSummaryPage} />
            <Route path="/mailingList" exact={true} component={MailingListPage} />

            <Route path="/enquiriesDashboard/leap" exact={true} component={LeadsDashboardPage} />
            <Route path="/clientsAndContacts/leap" exact={true} component={NameSummaryPage} />
            <Route path="/referrers/leap" exact={true} component={ReferrersPage} />
            <Route path="/MailingList/leap" exact={true} component={MailingListPage} />
            <Route path="/GeneralMaterial" exact={true} component={GeneralMaterialPage}  />
            <Route path="/clientDocuments" exact={true} component={ClientDocumentsPage}  />

            <Route 
                path="/admin/chart/emailFailures" 
                exact={true} 
                component={EmailSendFailureChartPage} 
            />
            <Route 
                path="/admin/emailFailures" 
                exact={true} 
                component={EmailSendFailurePage} 
            /> 
            <Route 
                path="/admin/emailFailures/:command" 
                exact={true} 
                component={EmailSendFailurePage} 
            />
            <Route 
                path="/admin/emailFailures/:filter/:command" 
                exact={true} 
                component={EmailSendFailurePage} 
            /> 

            <Route 
                path="/admin/chart/matterAccess" 
                exact={true} 
                component={MatterAccessChartPage} 
            />
            <Route 
                path="/admin/matterAccess" 
                exact={true} 
                component={MatterAccessPage} 
            /> 
            <Route 
                path="/admin/matterAccess/:command" 
                exact={true} 
                component={MatterAccessPage} 
            />
            <Route 
                path="/admin/matterAccess/:filter/:command" 
                exact={true} 
                component={MatterAccessPage} 
            />                        
            <Route 
                path="/admin/nameAccess" 
                exact={true} 
                component={NameAccessPage} 
            /> 
            <Route 
                path="/admin/nameAccess/:command" 
                exact={true} 
                component={NameAccessPage} 
            />
            <Route 
                path="/admin/nameAccess/:filter/:command" 
                exact={true} 
                component={NameAccessPage} 
            />
            <Route 
                path="/admin/chart/nameAccess" 
                exact={true} 
                component={NameAccessChartPage} 
            />

            <Route 
                path="/admin/marketingListAccess" 
                exact={true} 
                component={MarketingListAccessPage} 
            /> 
            <Route 
                path="/admin/marketingListAccess/:command" 
                exact={true} 
                component={MarketingListAccessPage} 
            />
            <Route 
                path="/admin/marketingListAccess/:filter/:command" 
                exact={true} 
                component={MarketingListAccessPage} 
            />

            <Route 
                path="/admin/chart/marketingListAccess" 
                exact={true} 
                component={MarketingListAccessChartPage} 
            />

            <Route 
                path="/admin/loginActivity" 
                exact={true} 
                component={LoginActivityPage} 
            /> 
            <Route 
                path="/admin/loginActivity/:command" 
                exact={true} 
                component={LoginActivityPage} 
            />
            <Route 
                path="/admin/loginActivity/:filter/:command" 
                exact={true} 
                component={LoginActivityPage} 
            />

            <Route 
                path="/admin/dashboardActivity" 
                exact={true} 
                component={UserDashboardActivityListPage} 
            /> 

            <Route 
                path="/admin/chart/loginActivity" 
                exact={true} 
                component={LoginActivityChartPage} 
            />
            <Route 
                path="/admin/chart/dashboardAccess" 
                exact={true} 
                component={DashboardAccessPage} 
            />
            <Route 
                path="/timesheetDrilldown" 
                exact={true} 
                component={TimesheetDrilldownPage} 
            /> 

            <Route 
                path="/staffBillingSummaryDrilldown" 
                exact={true} 
                component={StaffBillingSummaryPage} 
            />
            
            <Route 
                path="/admin/timesheetActivity" 
                exact={true} 
                component={TimesheetActivityPage} 
            />  
            <Route 
                path="/admin/timesheetActivity/:command" 
                exact={true} 
                component={TimesheetActivityPage} 
            />
            <Route 
                path="/admin/timesheetActivity/:filter/:command" 
                exact={true} 
                component={TimesheetActivityPage} 
            />
            <Route 
                path="/admin/chart/timesheetActivity" 
                exact={true} 
                component={TimelinesChartPage} 
            />                     
            <Route path="/admin/messageActivity" exact={true} component={MessageActivityPage} />
            <Route path="/admin/messageActivity/:command" exact={true} component={MessageActivityPage} />
            <Route path="/admin/roleSummary" exact={true} component={RoleSummaryPage} /> 
            <Route path="/admin/roleSummary/:command" exact={true} component={RoleSummaryPage} />   
            <Route path="/settings" exact={true} component={Settings} />
            <Route path="/login" exact={true} component={Redirect} />
            {/* <Route path="/autoSignIn/:token/:redirectUrl" exact={true} component={AutoSignIn} /> */}
            <Route path="/autoSignIn" exact={true} component={AutoSignIn} />
            <Route path="/externalEnquiry" exact={true} component={ExternalEnquiry} />
            <Route 
                path="/ClientService" 
                exact={true} 
                // tslint:disable-next-line:max-line-length
                component={() => { window.location.href = 'https://www.redraincorp.com/features-1/#staff-mobility'; return null; }} 
            />
            <Route 
                path="/clientsense"  
                exact={true} 
                // render={(props) => {
                //   // let url = { params: { url: 'http://clientsense/app/RelationshipManagement' } };
                //   let url = { params: { url: 'https://prodredviewaus.blob.core.windows.net/images/ClientSense.PNG' } };
                //   return <UrlViewer match={url}/>;
                // }}
                component={() => { 
                // tslint:disable-next-line
                // eslint-disable-next-line jsx-a11y/alt-text
                return <img 
                            src="https://prodredviewaus.blob.core.windows.net/images/ClientSense.PNG" 
                            style={{width: '70%', height: 'auto', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} 
                        // tslint:disable-next-line:jsx-alignment
                        />; 
                    }} 
            />
            <Route 
                path="/innography"  
                exact={true} 
                render={(props) => {
                let url = { params: { url: 'https://app.innography.com/' } };
                return <UrlViewer match={url}/>;
                }}
            />
            <Route 
                path="/:tenantId"  
                exact={true} 
                render={(props) => <Login 
                                    onLogin={this.onLogin}  
                                    onTenant={this.onTenant} 
                                    {...props} 
                                    // tslint:disable-next-line:jsx-alignment
                                    />
                        }
            />
            {/* <Route path="/:tenantId" exact={true} component={Login} /> */}
            <Route
                path="/:tenantId/login" 
                exact={true}
                render={(props) => (<Login 
                                    onLogin={this.onLogin} 
                                    onTenant={this.onTenant} 
                                    {...props} 
                                    // tslint:disable-next-line:jsx-alignment
                                    />)
                        }
            />
            <Route
                path="/:tenantId/verify/:verificationCode" 
                exact={true}
                render={(props) => (<VerifyNewUser
                                    onTenant={this.onTenant}
                                    {...props} 
                                    // tslint:disable-next-line:jsx-alignment
                                    />)}
            />
            <Route
                path="/:tenantId/logout" 
                exact={true}
                render={(props) => <Logout onLogout={this.onLogout} {...props} />}
            />
            <Route
                path="/logout" 
                exact={true}
                render={(props) => <Logout onLogout={this.onLogout} {...props} />}
            />          
            <Route path="/" exact={true} component={Redirect} />
            {/*   <Route component={Redirect}/> */}
          </Switch>
        );

        // eslint-disable-next-line
        let hideHeadFooter = location.pathname.toLowerCase() === '/leapprovisioninginprogress' 
                                  || location.pathname.toLowerCase() === '/leapprovisioning'
                                  || location.pathname.toLowerCase() === '/leapaccessreset'
                                  || location.pathname.toLowerCase() === '/feeearnerdashboard/leap'
                                  || location.pathname.toLowerCase() === '/dashboard/leap'
                                  || location.pathname.toLowerCase() === '/autosignin'
                                  || location.pathname.toLowerCase() === '/externalenquiry'
                                  || location.pathname.toLowerCase() === '/enquiriessummary/leap'
                                  || location.pathname.toLowerCase() === '/enquiriesdashboard/leap'
                                  || location.pathname.toLowerCase() === '/clientsandcontacts/leap'
                                  || location.pathname.toLowerCase() === '/referrers/leap'
                                  || location.pathname.toLowerCase() === '/mailinglist/leap';

        let noHeaderAndFooterRoute = location.pathname.includes('/timeslip') || location.pathname.includes('/createEnquiry');

        var appHeader = hideHeadFooter || noHeaderAndFooterRoute
            ? null
            : (
              <AppHeader     
                key={this.getAppHeaderKey()}    
                tenantId={this.state.tenantId} 
                isAuthenticated={this.state.isAuthenticated}
                onToggleMenu={this.onShowMenu} 
                isForceToChangePass={this.state.isForceToChangePass}
                onUploadDocuments={this.onUploadDocuments}
                onRequestSupport={this.onRequestSupport}
                onClickInstructMatter={this.onClickInstructMatter}
                onToggleDrawer={this.onToggleDrawer}
              />
        );

        var appFooter = hideHeadFooter || noHeaderAndFooterRoute ? null : <AppFooter />;

        var mainClass = hideHeadFooter || noHeaderAndFooterRoute ? '' : 'custom-site-content';

        return (
            <ThemeProvider theme={mainTheme}>
                <SiteParent className={this.state.isPrint ? 'site-onprint' : ''}>
                    <header className={`header ${hideHeadFooter || noHeaderAndFooterRoute ? 'timeslip-header' : ''}`}>
                        {appHeader}
                    </header>
                    <NotificationSystem
                        ref={this.props.notificationSystem}
                    />
                    <main className={`Site-content ${mainClass}`} style={{ paddingBottom: noHeaderAndFooterRoute ? 0 : '15px'}}>
                        <Router history={browserHistory}> 
                            <div className="site-child">
                              <AppMenu show={this.state.showMenu}>                   
                                  {routes}  
                              </AppMenu>                 
                            </div>
                        </Router>  
                    </main>
                    <footer>
                        {appFooter}
                    </footer>
                    {UserFeature.HasAccess(UserFeatureEnum.hasUploadMatterDocument) && this.state.isUploadDialogOpen && (
                        <UploadDocumentDialog 
                            onClose={this.onUploadClose}                          
                        />
                    )}
                    {this.state.isSupportDialogOpen && (
                        <SupportEmailDialog
                            onClose={this.onRequestSupportClose}
                        />
                    )}
                    {this.state.isInstructMatterDialogOpen && (
                        <InstructMatterFormDialog 
                            open={this.state.isInstructMatterDialogOpen}
                            onClose={this.onInstructMaterClose}
                        />
                    )}
                    {this.state.isTimerDrawerOpen && (
                        <TimesheetTimerDrawer 
                            onClose={this.onToggleDrawer}
                            open={this.state.isTimerDrawerOpen}
                        />
                    )}
                </SiteParent>
            </ThemeProvider>
        );
    }

    private subscribeToUploadMatterDocument() {      
        // let vm = this;
        this.uploadMatterDocumentSubscription = client.subscribe({
            query: UploadMatterDocumentNotification
        }).subscribe({
            // tslint:disable-next-line:no-any
            next(results: any) {
                // tslint:disable-next-line:no-console
                console.log(results);
                if (results.data.uploadMatterDocumentNotification.status) {
                    showNotification('Success', results.data.uploadMatterDocumentNotification.message, 'info');

                    // vm.setState({
                    //     isUploadDialogOpen: false
                    // });

                } else {
                    showNotification('Failed', results.data.uploadMatterDocumentNotification.message, 'error');
                }
            },            
            // tslint:disable-next-line:no-any
            error(err: any) {
                // tslint:disable-next-line:no-console
                console.error(err);
                showNotification(null, err, 'error');
            }
        });
    }

    private subscribePendingMatter() {
        this.pendingMatterSubscription = client.subscribe({
            query: CreatePendingMattersSubscription
        }).subscribe({
            // tslint:disable-next-line:no-any
            next(results: any) {
                // tslint:disable-next-line:no-console
                console.log(results);
                if (results.data.createPendingMatterNotification.status) {
                    showNotification('Success', results.data.createPendingMatterNotification.message, 'info');

                } else {
                    showNotification('Failed', results.data.createPendingMatterNotification.message, 'error');
                }
            },            
            // tslint:disable-next-line:no-any
            error(err: any) {
                // tslint:disable-next-line:no-console
                console.error(err);
                showNotification(null, err, 'error');
            }
        });
    }

    private subscribeInstructMatter() {
        this.pendingMatterSubscription = client.subscribe({
            query: CreateInstructMatterSubscription
        }).subscribe({
            // tslint:disable-next-line:no-any
            next(results: any) {
                // tslint:disable-next-line:no-console
                console.log(results);
                if (results.data.createInstructMatterNotifications.status) {
                    showNotification('Success', results.data.createInstructMatterNotifications.message, 'info');

                } else {
                    showNotification('Failed', results.data.createInstructMatterNotifications.message, 'error');
                }
            },            
            // tslint:disable-next-line:no-any
            error(err: any) {
                // tslint:disable-next-line:no-console
                console.error(err);
                showNotification(null, err, 'error');

                close();
            }
        });
    }

    private onUploadDocuments() {
        this.setState({
            isUploadDialogOpen: true
        });
    }

    private onUploadClose() {
        
        this.setState({
            isUploadDialogOpen: false
        });
    }

    private onRequestSupport() {
        this.setState({
            isSupportDialogOpen: true
        });
    }

    private onRequestSupportClose() {
        
        this.setState({
            isSupportDialogOpen: false
        });
    }

    private onLogin( loggedIn: boolean) {
        // set the callback
        // history.push('/mattersSummary');
        this.setState({isAuthenticated: loggedIn});
        // if (this.props.history) { this.props.history.push('/mattersSummary'); }

        if (loggedIn) {
            if (!this.isSubscribed) {
                this.isSubscribed = true;
                this.subscribeToUploadMatterDocument();
            }
        }       
    }

    private onLogout( ) {
      this.setState({isAuthenticated: false});
    }

    private onTenant( tenantId: string) {
        // set the callback
        this.setState({tenantId: tenantId});
    }  

    private onShowMenu( show: boolean) {
        this.setState({showMenu: show});
    }

    private getAppHeaderKey() {
        return this.state.isAuthenticated ? this.authenticatedUserKey : this.unAuthenticatedUserKey;
    }

    private onClickInstructMatter() {
        this.setState({
            isInstructMatterDialogOpen: true
        });
    }

    private onInstructMaterClose() {
        this.setState({
            isInstructMatterDialogOpen: false
        });
    }

    private onToggleDrawer() {
        this.setState({
            isTimerDrawerOpen: !this.state.isTimerDrawerOpen
        });
    }

    private getDefaultSetting() {
        client.query<DefaultSettingsData>({
            query: DefaultSettingQuery,
            fetchPolicy: 'cache-first'
            // tslint:disable-next-line:no-any
        }).then((results: ApolloQueryResult<DefaultSettingsData>) => {    
                
                if (
                    results.data &&
                    results.data.settings &&
                    results.data.settings.systems &&
                    results.data.settings.systems.regionSettings &&
                    results.data.settings.systems.regionSettings.config
                ) {
                    const { homeCountry } = results.data.settings.systems.regionSettings.config;

                    let lang = undefined;

                    if (homeCountry) {
                        lang = getLanguageCodeByCountry(homeCountry.toUpperCase() as any);
                    } else {
                        lang = getLanguageCodeByCountry('AU');
                    }

                    i18n.changeLanguage(lang);
                }
                
                if (results.data && results.data.settings.applications && results.data.settings.applications.labelSettings) {
                    
                    const { personActingLabel, personResponsibleLabel } = results.data.settings.applications.labelSettings.config;

                    if (personActingLabel) {
                        localStorage.setItem(LocalStorageEnum.PersonActing, personActingLabel);
                    } else {
                        localStorage.setItem(LocalStorageEnum.PersonActing, 'Person Acting');
                    }

                    if (personResponsibleLabel) {
                        localStorage.setItem(LocalStorageEnum.PersonResponsible, personResponsibleLabel);
                    } else {
                        localStorage.setItem(LocalStorageEnum.PersonResponsible, 'Person Responsible');
                    }

                }

            // tslint:disable-next-line:no-any
            }).catch((reason: any) => {
                showNotification(null, reason, 'error');
        });
    }
}

type DefaultSettingsData = {
    settings: Settings;
};

type Settings = {
    id:           number;
    systems:      Systems;
    applications: Applications;
};

type Applications = {
    labelSettings: LabelSettings;
};

type LabelSettings = {
    config: LabelSettingsConfig;
};

type LabelSettingsConfig = {
    personActingLabel:      string;
    personResponsibleLabel: string;
};

type Systems = {
    id:             number;
    regionSettings: RegionSettings;
};

type RegionSettings = {
    id:     number;
    config: RegionSettingsConfig;
};

type RegionSettingsConfig = {
    id:          number;
    homeCountry: string;
};

const DefaultSettingQuery = gql`
    query DefaultSettingQuery {
        settings {
            id
            systems {
                id
                regionSettings {
                    id
                    config {
                        id
                        homeCountry
                    }
                }
            }
            applications {
                labelSettings {
                    config {
                        personActingLabel
                        personResponsibleLabel
                    }
                }
            }
        }
    }
`;