import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { EmailTemplateTypeEnum } from '../emailTemplatesConfiguration/EmailTemplateRespository';
import { streamingAIRequest } from './AiHelper';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';

interface ToolbarGroup {
    name?: string;
    items: string[];
}

interface EditorProps {
    // tslint:disable-next-line: no-any
    value: string;
    onEditorChange?: (content: string) => void;
    disabled?: boolean;
    toolbar?: boolean | string | string[] | Array<ToolbarGroup>;
    menubar?: boolean | string;
    emailType?: EmailTemplateTypeEnum;
    disabledAI?: boolean;
}

export const EditorWrapper: React.FC<EditorProps> = props => {

    // tslint:disable-next-line: no-any
    const getMergeFields = () => {
        if (props.emailType) {
            switch (props.emailType) {
                case EmailTemplateTypeEnum.NEW_ENQUIRY_RESPONSE:
                case EmailTemplateTypeEnum.NEW_LEAD_ASSIGNED:
                case EmailTemplateTypeEnum.ENQUIRY_MENTIONS:
                case EmailTemplateTypeEnum.FOLLOW_UP_ENQUIRY_REMINDER:
                case EmailTemplateTypeEnum.FOLLOW_UP_ENQUIRY_OVER_DUE_REMINDER:
                case EmailTemplateTypeEnum.MEETING_BOOKING:
                case EmailTemplateTypeEnum.NEW_CLIENT:
                case EmailTemplateTypeEnum.EMAIL_RESPONSE:
                case EmailTemplateTypeEnum.NEW_LEAD:
                case EmailTemplateTypeEnum.NEW_MEETING_NOTIFICATION:
                case EmailTemplateTypeEnum.RecordCreatedConfirmation:
                case EmailTemplateTypeEnum.RecordCreationFailed:
                case EmailTemplateTypeEnum.UnassignedEnquiry:
                    return enquiryMergedFields;
                case EmailTemplateTypeEnum.PHONE_MESSAGE:
                case EmailTemplateTypeEnum.TASK_ASSIGNED:
                case EmailTemplateTypeEnum.TASK_COMPLETED:
                case EmailTemplateTypeEnum.TaskMessage:
                case EmailTemplateTypeEnum.TASK_REMINDER:
                case EmailTemplateTypeEnum.TASK_OVER_DUE_REMINDER:
                    return taskMergedFields;
                case EmailTemplateTypeEnum.NEW_USER_CREATED:
                    return userRegistrationMergedFields;
                case EmailTemplateTypeEnum.PASSWORD_RESET:
                    return resetPasswordMergedFields;
                case EmailTemplateTypeEnum.NEW_MATTER_DOCUMENT:
                    return newMatterDocumentMergedFields;
                case EmailTemplateTypeEnum.NEW_MATTER_MESSAGE_SENT:
                    return newMatterMessageMergedFields;
                case EmailTemplateTypeEnum.InstructMatter:
                    return newInstructMatterMergedFields;
                case EmailTemplateTypeEnum.EmailSendFailureNotification:
                    return emailSendFailureMergedFields;
                default: // EmailTemplateTypeEnum.MFA:
                    return mFaMergedFields;
            }
        } else {
            return [];
        }
    };

    const getMergeFieldItems = () => {
        if (props.emailType) {
            const mergedFields = getMergeFields();
            let mergeItemdItemText = '';
            
            // tslint:disable-next-line: no-any
            mergedFields.forEach((field: any) => {
                mergeItemdItemText = mergeItemdItemText + ' ' + field.value;
            });

            // tslint:disable-next-line: no-console
            // console.log('Merged Field Items', mergeItemdItemText);

            return mergeItemdItemText;
        } else {
            return '';
        }
    };

    // tslint:disable-next-line: no-any
    const getMergeFieldMenuItems = (editor: any) => {
        if (props.emailType) {
            const mergedFields = getMergeFields();

            // tslint:disable-next-line: no-any
            mergedFields.forEach((field: any) => {
                editor.ui.registry.addMenuItem(field.value, {
                    text: field.text,
                    onAction: function () {
                      editor.insertContent(field.value);
                    },
                });  
            });
        }
    };

    const hasArtificialIntelligence = UserFeature.HasAccess(UserFeatureEnum.hasArtificialIntelligence) && !props.disabledAI;

    const hasEmailTemplateAI = UserFeature.HasAccess(UserFeatureEnum.hasEmailTemplateAI) && !props.disabledAI;

    return (
        <Editor
            apiKey={'k74nddluc9fmtf75en31ew8mxzqcvovgpjkzomdtgeje7b0h'}            
            value={props.value}
            disabled={props.disabled}           
            init={{        
                ai_request: streamingAIRequest,
                ai_shortcuts: true,
                height: 500,
                menubar: props.menubar !== undefined ? props.menubar : 'file edit view insert format tools table help custom',
                imagetools_cors_hosts: ['picsum.photos'],
                icons: 'material',
                plugins: `${hasArtificialIntelligence && hasEmailTemplateAI ? 'ai ' : ''}table code advtable lists fullscreen image media link preview export pagebreak lists advlist checklist charmap directionality`,
                toolbar: `${hasArtificialIntelligence ? 'aidialog ' : ''} ${hasArtificialIntelligence && hasEmailTemplateAI ? 'aishortcuts ' : ''}| undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | ' +
                    'forecolor backcolor | alignleft aligncenter alignright alignjustify indent outdent rtl ltr | bullist numlist checklist | '+
                    'emoticons image media table link hr charmap | preview | fullscreen | export pagebreak',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }`,
                toolbar_sticky: true,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                content_css: '//www.tiny.cloud/css/codepen.min.css',
                image_class_list: [
                    { title: 'None', value: '' },
                    { title: 'Some class', value: 'class-name' }
                ],
                importcss_append: true,
                template_cdate_format: '%m/%d/%Y : %H:%M:%S',
                template_mdate_format: '%m/%d/%Y : %H:%M:%S',
                // template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                // template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                image_caption: true,
                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                menu: {
                    custom: { title: 'Merge Fields', items: getMergeFieldItems() }
                },
                // tslint:disable-next-line: no-any
                setup: function (editor: any) {
                    // var toggleState = false;
                    getMergeFieldMenuItems(editor);

                    // tslint:disable-next-line: no-any
                    var onAction = function (autocompleteApi: any, rng: any, value: any) {
                        editor.selection.setRng(rng);
                        editor.insertContent(value);
                        autocompleteApi.hide();
                    };
                
                    // tslint:disable-next-line: no-any
                    var getMatchedChars = function (pattern: any) {
                        const mergedFields = getMergeFields();
                        if (mergedFields) {
                            // tslint:disable-next-line: no-any
                            return mergedFields.filter(function (char: any) {
                                if (pattern && char.text) {
                                    return char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                } else {
                                    return char.text.toLowerCase().indexOf(pattern) !== -1;
                                }        
                            });
                        } else {
                            return [];
                        }                        
                    };
                
                    /* An autocompleter that allows you to insert special characters */
                    editor.ui.registry.addAutocompleter('specialchars', {
                        trigger: '{',
                        minChars: 0,
                        columns: 1,
                        highlightOn: ['char_name'],
                        onAction: onAction,
                        // tslint:disable-next-line: no-any
                        fetch: function (pattern: any) {
                            // tslint:disable-next-line: no-any
                            return new Promise(function (resolve: any) {
                                // tslint:disable-next-line: no-any
                                var results = getMatchedChars(pattern).map(function (char: any) {
                                    return {
                                        type: 'cardmenuitem',
                                        value: char.value,
                                        label: char.text,
                                        items: [
                                            {
                                                type: 'cardcontainer',
                                                direction: 'vertical',
                                                items: [
                                                    {
                                                        type: 'cardtext',
                                                        text: char.text,
                                                        name: 'char_name'
                                                    },
                                                ]
                                            }
                                        ]
                                    };
                                });
                                resolve(results);
                            });
                        }
                    });

                    editor.ui.registry.addContextMenu('quickimage', {
                        update: function(element: any) {
                          return ['image'];
                        }
                    });

                    // Register custom button for AI suggestions
                    editor.ui.registry.addButton('aidialog', {
                        text: 'AI Suggestions',
                        onAction: function () {
                            let stopButton: any;

                            // Open a dialog
                            const dialogApi = editor.windowManager.open({
                                title: 'AI Suggestions',
                                body: {
                                    type: 'panel',
                                    items: [
                                        {
                                            type: 'textarea',
                                            name: 'aiContent',
                                            label: 'AI Suggestions',
                                            disabled: false  // Ensure the text area is not disabled
                                        }
                                    ]
                                },
                                buttons: [
                                    {
                                        type: 'custom',
                                        name: 'insert',
                                        text: 'Insert',
                                        primary: true,
                                        enabled: true
                                    },
                                    {
                                        type: 'custom',
                                        name: 'stop',
                                        text: 'Stop',
                                        primary: false,
                                        enabled: false,  // Initially disabled
                                        onAction: function () {
                                            // Stop the AI request
                                            stopButton.setEnabled(false);
                                            // Implement stopping functionality here
                                        }
                                    },
                                    {
                                        type: 'cancel',
                                        text: 'Close'
                                    }
                                ],
                                onSubmit: function (api: any) {
                                    const data = api.getData();
                                    editor.insertContent(data.aiContent);
                                    api.close();
                                },
                                onChange: function (api: any) {
                                    const data = api.getData();
                                    // Enable the stop button if AI request is in progress
                                    if (data.aiContent) {
                                        stopButton.setEnabled(true);
                                    }
                                }
                            });
                            stopButton = dialogApi.getButton('stop');
                        }
                    });
                },
                
            }}
            onEditorChange={(content: string) => {
                if (props.onEditorChange) {
                    props.onEditorChange(content);
                }
            }}            
        />
    );
};

/*
Enquiry Fields are available in the following templates.
1.	Enquiry Response Template
2.	Enquiry Assignment
3.	Enquiry Mention
4.	Follow Up Reminder
5.	Follow Up Overdue Reminder
6.	Meeting Booking Confirmation
7.	New Client/ New Matter
8.	New Enquiry Acknowledgement 
9.	New Enquiry Notification

*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const enquiryMergedFields = [
    { text: 'All Day',                                          value: '{isAllDay}' },
    { text: 'Application URL',                                  value: '{applicationURL}' },
    { text: 'Area Of Law',                                      value: '{areaOfLaw}' },
    { text: 'Assigned By',                                      value: '{assignedBy}' },
    { text: 'Assigned To Email',                                value: '{assignedToEmail}' },
    { text: 'Assigned To',                                      value: '{assignedTo}' },
    { text: 'Client Address',                                   value: '{clientAddress}' },
    { text: 'Client Address Fomatted',                          value: '{clientAddressFormatted}' },
    { text: 'Client Date Of Birth',                             value: '{clientDateOfBirth}' },
    { text: 'Client Email',                                     value: '{clientEmail}' },
    { text: 'Client First Name',                                value: '{clientFirstName}' },
    { text: 'Client Last Name',                                 value: '{clientLastName}' },
    { text: 'Client Mobile',                                    value: '{clientMobile}' },
    { text: 'Client Phone',                                     value: '{clientPhone}' },
    { text: 'Client Title',                                     value: '{clientTitle}' },
    { text: 'Conflict Check',                                   value: '{conflictCheck}' },
    { text: 'Detailed Enquiry Notes',                           value: '{detailedEnquiryNotes}' },
    { text: 'Email',                                            value: '{email}' },
    { text: 'Enquiry Created Date',                             value: '{createdDate}' },
    { text: 'Enquiry Location',                                 value: '{location}' },
    { text: 'Enquiry Quality',                                  value: '{enquiryQuality}' },
    { text: 'Enquiry Text',                                     value: '{enquiryText}' },
    { text: 'Estimated Disbursements',                          value: '{estimatedDisbursements}' },
    { text: 'Estimated Fees',                                   value: '{estimatedFees}' },
    { text: 'Firm Name',                                        value: '{firmName}' },
    { text: 'Follow Up Notes',                                  value: '{followUpNotes}' },
    { text: 'Gender',                                           value: '{gender}' },
    { text: 'Given Name',                                       value: '{firstName}' },
    { text: 'Identity Check',                                   value: '{identityCheck}' },
    { text: 'Initial Enquiry Date',                             value: '{inquiryDate}' },
    { text: 'Internal Notes',                                   value: '{internalnotes}' },
    { text: 'Last Name',                                        value: '{lastName}' },
    { text: 'Likelihood to Proceed',                            value: '{likelihoodToProceed}' },
    { text: 'Marketing',                                        value: '{marketing}' },
    { text: 'Meeting Created By Email',                         value: '{bookingCreatedByEmail}' },
    { text: 'Meeting Created By',                               value: '{bookingCreatedByName}' },
    { text: 'Meeting End Date',                                 value: '{bookingEndDate}' },
    { text: 'Meeting Fee Applicable',                           value: '{feeApplicable}' },
    { text: 'Meeting Location',                                 value: '{meetingLocation}' },
    { text: 'Meeting Notes',                                    value: '{meetingNotes}' },
    { text: 'Meeting Staff Email',                              value: '{bookingStaffEmail}' },
    { text: 'Meeting Staff',                                    value: '{bookingStaffName}' },
    { text: 'Meeting Start Date',                               value: '{bookingStartDate}' },
    { text: 'Meeting Location Timezone',                        value: '{meetingLocationTimezone}' },
    { text: 'Mobile',                                           value: '{mobile}' },
    { text: 'Name',                                             value: '{name}' },
    { text: 'Nature Of Enquiry',                                value: '{natureOfEnquiry}' },
    { text: 'No Conflict',                                      value: '{noConflict}' },
    { text: 'Organisation',                                     value: '{organisation}' },
    { text: 'Other Side Address',                               value: '{otherSideAddress}' },
    { text: 'Other Side AddressFormatted',                      value: '{otherSideAddressFormatted}' },
    { text: 'Other Side Birthdate',                             value: '{otherSideBirthdate}' },
    { text: 'Other Side Email',                                 value: '{otherSideEmail}' },
    { text: 'Other Side Given Name',                            value: '{otherSideGivenName}' },
    { text: 'Other Side Mobile',                                value: '{otherSideMobile}' },
    { text: 'Other Side Notes',                                 value: '{otherSideNotes}' },
    { text: 'Other Side Organisation',                          value: '{otherSideOrganisation}' },
    { text: 'Other Side Surname',                               value: '{otherSideSurname}' },
    { text: 'Other Side Telephone',                             value: '{otherSideTelephone}' },
    { text: 'Other Side Title',                                 value: '{otherSideTitle}' },
    { text: 'Phone',                                            value: '{phone}' },
    { text: 'Position',                                         value: '{position}' },
    { text: 'Privacy',                                          value: '{privacy}' },
    { text: 'Referrer Name',                                    value: '{referrerName}' },
    { text: 'Second Client First Name',                         value: '{secondClientFirstName}' },
    { text: 'Second Client Last Name',                          value: '{secondClientLastName}' },
    { text: 'Second Client Email',                              value: '{secondClientEmail}' },
    { text: 'Second Client Phone',                              value: '{secondClientPhone}' },
    { text: 'Second Client Mobile',                             value: '{secondClientMobile}' },
    { text: 'Second Client Relationship to the Enquirer',       value: '{secondClientRelationshipToEnquirer}' },
    { text: 'Second Client Birthdate',                          value: '{secondClientDateOfBirth}' },
    { text: 'Second Client Gender',                             value: '{secondClientGender}' },
    { text: 'Second Client Marketing Consent',                  value: '{secondClientMarketing}' },
    { text: 'Second Client Privacy Policy',                     value: '{secondClientPrivacy}' },
    { text: 'Second Client T & Cs Approved',                    value: '{secondClientTermsAndConditionsApproved}' },
    { text: 'Second Client Conflict Check',                     value: '{secondClientConflictCheck}' },
    { text: 'Second Client Identity Check',                     value: '{secondClientIdentityCheck}' },
    { text: 'Second Client No Conflict',                        value: '{secondClientNoConflict}' },
    { text: 'Second Client Address',                            value: '{secondClientAddress}' },
    { text: 'Second Client Address Formatted',                  value: '{secondClientAddressFormatted}' },
    { text: 'Second Client Title',                              value: '{secondClientTitle}' },
    { text: 'Source Notes',                                     value: '{sourceNotes}' },
    { text: 'Source',                                           value: '{source}' },
    { text: 'T & Cs Approved',                                  value: '{termsAndConditionsApproved}' },
    { text: 'Title',                                            value: '{title}' },
    { text: 'User Name',                                        value: '{staffName}' },   
    { text: 'Risk Assessed',                                    value: '{isRiskAssessed}' },
    { text: 'Risk Level',                                       value: '{riskLevel}' },
    { text: 'Risk Notes',                                       value: '{riskNotes}' }
].sort(function(a, b) {
    return a.text.localeCompare(b.text);
});  

/*
Task Fields are available in the following templates
1.	Phone Message
2.	Task Assignment
3.	Task Completed
*/
const taskMergedFields = [
    { text: 'Application URL',                                  value: '{applicationURL}' },
    { text: 'Area Of Law',                                      value: '{areaOfLaw}' },
    { text: 'Assigned By',                                      value: '{assignedBy}' },
    { text: 'Assigned To',                                      value: '{assignedTo}' },
    { text: 'Assigned To Email',                                value: '{assignedToEmail}' },
    { text: 'Category',                                         value: '{category}' },
    { text: 'Client',                                           value: '{client}' },
    { text: 'Completion Date',                                  value: '{completionDate}' },
    { text: 'Completion Notes',                                 value: '{completionNotes}' },
    { text: 'Created Date',                                     value: '{createdDate}' },
    { text: 'Description',                                      value: '{description}' },
    { text: 'Due Date',                                         value: '{dueDate}' },
    { text: 'Duration',                                         value: '{duration}' },
    { text: 'Estimated Effort',                                 value: '{estimatedEffort}' },
    { text: 'From Name',                                        value: '{fromName}' },   
    { text: 'Matter',                                           value: '{matter}' },
    { text: 'Message',                                          value: '{message}' },
    { text: 'Message Sent By',                                  value: '{messageSentBy}' },
    { text: 'Notes',                                            value: '{notes}' },
    { text: 'Organisation',                                     value: '{organisation}' },
    { text: 'Owner Email',                                      value: '{ownerEmail}' },
    { text: 'Owner',                                            value: '{ownerName}' },       
    { text: 'Phone Call',                                       value: '{isPhoneCall}' },
    { text: 'Phone Number',                                     value: '{phoneNumber}' },
    { text: 'Priority',                                         value: '{priority}' },    
    { text: 'Returned Call',                                    value: '{isReturnedCall}' },
    { text: 'Start Date',                                       value: '{startDate}' },
    { text: 'Task Type',                                        value: '{taskType}' },
    { text: 'Will Call again',                                  value: '{isWillCallAgain}' }
];

/*
User Related Fields available in the following templates
1.	User Registration
*/
const userRegistrationMergedFields = [
    { text: 'Application URL',                                  value: '{applicationURL}' },
    { text: 'Company Name',                                     value: '{companyName}' },
    { text: 'Password',                                         value: '{password}' },
    { text: 'Tenant ID',                                        value: '{tenantId}' },
    { text: 'UserName',                                         value: '{userName}' },
    { text: 'Verify Link',                                      value: '{VerifyLink}' }
];

/* 
2.	Reset Password
*/
const resetPasswordMergedFields = [
    { text: 'Password',                                         value: '{password}' },
    { text: 'UserName',                                         value: '{userName}' },
    { text: 'Verify Link',                                      value: '{VerifyLink}' }
];

/* 
3.	Multi Factor Authentication
*/
const mFaMergedFields = [
    { text: 'MFA Code',                                        value: '{Code}' },
    { text: 'Timeout in minutes',                              value: '{Timeout}' }
];

/* 
Matter Fields available in the following templates
1.	New Matter Document
*/
const newMatterDocumentMergedFields = [
    { text: 'Application URL',                                  value: '{applicationURL}' },
    { text: 'Client',                                           value: '{client}' },
    { text: 'Document Name',                                    value: '{documentNames}' },
    { text: 'Matter File Number',                               value: '{fileNumber}' },
    { text: 'Matter Title',                                     value: '{matterTitle}' },
    { text: 'Notes',                                            value: '{notes}' },
    { text: 'Responsible',                                      value: '{responsible}' },
    { text: 'Uploaded By Name',                                 value: '{uploadedBy}' },
];

// New Message (New Matter Message)
const newMatterMessageMergedFields = [
    { text: 'Comment',                                          value: '{comment}' },
    { text: 'Matter Id',                                        value: '{matterId}' },
    { text: 'Matter File Number',                               value: '{fileNumber}' },
    { text: 'Matter Title',                                     value: '{matterTitle}' },
    // tslint:disable-next-line: max-line-length
    { text: 'Message formatted as "Message from {postedBy} regarding matter {matterTitle}. {comment}"',              value: '{message}' }, // Formatted as following. "Message from {postedBy} regarding matter {matterTitle}. {comment}"    
    { text: 'Title Text',                                       value: '{title}' }, // Pre-defined title value as following “New message available”
    { text: 'Message Text',                                     value: '{type}' }, // Pre-defined value as following “message”
    { text: 'Posted By',                                        value: '{username}' }
];

// New Instruct matter
const newInstructMatterMergedFields = [
    { text: 'Instruction',                                  value: '{instruction}' },
    { text: 'Files',                                        value: '{files}' },
    { text: 'Area Of Law',                                  value: '{areaOfLaw}' },
    { text: 'Name',                                         value: '{name}' },
    { text: 'Organisation',                                 value: '{organization}' },
    { text: 'ClientName',                                   value: '{clientName}' },
    { text: 'ClientContact',                                value: '{clientContact}' },
    { text: 'MatterTitle',                                  value: '{matterTitle}' },
    { text: 'YourReference',                                value: '{yourReference}' },
    { text: 'OtherPartyType',                               value: '{otherPartyType}' },
    { text: 'OtherPartyName',                               value: '{otherPartyName}' },
    { text: 'OtherPartyAddress',                            value: '{otherPartyAddress}' },
    { text: 'OtherPartyContact',                            value: '{otherPartyContact}' },
    { text: 'OtherPartyPhone',                              value: '{otherPartyPhone}' },
    { text: 'OtherPartyEmail',                              value: '{otherPartyEmail}' },
    { text: 'DisputedAmount',                               value: '{disputedAmount}' }
];

const emailSendFailureMergedFields = [
    { text: 'Email Template Name', value: '{emailTemplateName}' },
    { text: 'Send Failure Reason', value: '{sendFailureReason}' },
    { text: 'Send Failure Date/Time', value: '{sendFailureDateTime}' },
    { text: 'Product Area', value: '{productArea}' },
    { text: 'Sender Name', value: '{senderName}' },
    { text: 'Sender Email', value: '{senderEmail}' },
    { text: 'Recipient Email', value: '{recipientEmail}' },
    { text: 'Triggered By User', value: '{triggeredByUserName}' },
    { text: 'Is External', value: '{isExternal}' },
    { text: 'Area of Law', value: '{areaOfLaw}' },
];